import React, {useEffect, useRef, useState} from "react"

import Layout from "src/components/layout"
import Head from "src/components/Head"
import Top from "src/components/Top/Top"

import PartnersBenefits from "src/components/partners/PartnersBenefits/PartnersBenefits"
import PartnersHowItWorks from "src/components/partners/PartnersHowItWorks/PartnersHowItWorks"
import Start from "src/components/Start/Start"

import {useTranslation} from "react-i18next"
import { graphql, useStaticQuery } from "gatsby"
import COUNTRY from "../../constants";
import BRANDS from "../../brands";

const ClientsMain = ({ location }) => {
  const data = useStaticQuery(graphql`
    query affiliatesPageClientsLtEn {
      site {
        siteMetadata {
          senderPath
          countries {
            lt {
              hotLine {
                tel
                title
                telephone
                telephoneTitle
              }
              statistics {
                supplier {
                  subscription_amount_array {
                    key
                    value
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  const platinum_amount =
    data.site.siteMetadata.countries.lt.statistics.supplier.subscription_amount_array.reduce(
      (acc, item) => Math.max(acc, item.value),
      0
    )

  const { t,i18n } = useTranslation()
  useEffect(() => {
    i18n.changeLanguage(COUNTRY.lt_en)
  }, [i18n])

  const [email, setEmail] = useState("")
  const [emailDisabled, setEmailDisabled] = useState(false)
  const [form, setForm] = useState({
    company: "",
  })
  const sendForm = (form) => {
    setForm(form)
    return fetch(data.site.siteMetadata.senderPath, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(form),
    })
      .then((response) => {
        alert(t("sendFormAlert"))
      })
      .catch((e) => {
        console.error(e)
      })
  }
  const transferEmail = (email) => {
    setForm(Object.assign(form, { email }))
    setEmailDisabled(true)
  }
  const startRef = useRef()

  return (
    <Layout
      hotLine={data.site.siteMetadata.countries.lt.hotLine}
      location={location}
      country="lt_en"
    >
      <Head
        lang="lt_en"
        title={t('head.affiliates.title' , { brand: BRANDS.SPORTBENEFIT.brand })}
        description={t('head.affiliates.description', { brand: BRANDS.SPORTBENEFIT.brand })}
        slug="/affiliates/"
      />
      <Top
        type="partners"
        startRef={startRef}
        email={email}
        setEmail={setEmail}
        transferEmail={transferEmail}
        platinum_amount={platinum_amount}
        county="lt_en"
      />
      <PartnersBenefits />
      <PartnersHowItWorks />
      <Start
        type="partners"
        startRef={startRef}
        email={email}
        emailDisabled={emailDisabled}
        setEmail={setEmail}
        setForm={sendForm}
      />
    </Layout>
  )
}

export default ClientsMain
